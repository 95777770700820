/* Grid Container */
.image-grid {
  display: grid;
  gap: 1rem; /* Adjust this to set the spacing between images */

  /* Mobile Devices: 2 columns */
  grid-template-columns: repeat(2, 1fr);

  /* Tablets: 6 columns */
  @media (min-width: 768px) and (max-width: 1199px) {
    grid-template-columns: repeat(6, 1fr);
  }

  /* Large Screens: 9 columns */
  @media (min-width: 1200px) {
    grid-template-columns: repeat(9, 1fr);
  }
  
  width: 100%; /* Ensures that the grid takes up the full width */
}

/* Image Card Styling */
.venueImageCard {
  width: 100%; /* Ensures that each card takes the full width of its grid cell */
  height: 200px; /* Adjust this as needed */
  position: relative;
  overflow: hidden;
  border: none;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

  

  .venueImage {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the card without distortion */
    transition: transform 0.3s ease;
  }

  &:hover .venueImage {
    transform: scale(1.05);
  }

  .venueImageCardOverlay {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: opacity 0.3s ease;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &:hover {
      opacity: 1;
    }

    .btn-bwny-secondary {
      color: #fff;
      background-color: #6c757d;
      border: none;
      padding: 0.5rem 1rem;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: darken(#6c757d, 10%);
      }
    }
  }
}
